
import React from 'react'
import { useRef } from 'react'
import {
  useEffect,
} from 'react'
import $ from 'jquery'
import type { PluginListenerHandle } from '@capacitor/core'
import { App as CapacitorApp } from '@capacitor/app'
import {
  Routes,
  Route,
} from 'react-router-dom'
import {
  useNavigate,
} from 'react-router-dom'

// css
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// helmet
import {
  HelmetProvider
} from 'react-helmet-async'

// redux
import { Provider } from 'react-redux'
import { makeStore, AppStore } from '../app/store'

// components
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import Aside from './Aside'

const Layout = () => {
  const navigate = useNavigate()

  const storeRef = useRef<AppStore>()

  if (!storeRef.current) {
    storeRef.current = makeStore()
  }

  useEffect(() => {
    const pathname_split_by_slash = window.location.pathname.split('/')

    const has_language = /en|ja|zh-hant|zh-hans/.test(pathname_split_by_slash[1])

    if (!has_language) {
      navigate(`/${has_language ? pathname_split_by_slash[1] : 'en'}`)
    }

    $('header').css('opacity', '0')
    $('aside').css('opacity', '0')
    $('main').css('opacity', '0')
    $('footer').css('opacity', '0')

    setTimeout( function () {
      $('header').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 200 )

    setTimeout( function () {
      $('aside').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 400 )

    setTimeout( function () {
      $('main').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 600 )

    setTimeout( function () {
      $('footer').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 800 )

    setTimeout( function () { 
      $('main').css(
        'min-height',
        (($(window).innerHeight() || 0) - ($('header').outerHeight() || 0) - ($('footer').outerHeight() || 0) - 4) + 'px'
      )
    }, 200)
  }, [])

  useEffect(() => {
    let listener: PluginListenerHandle | undefined
    const setupListener = async () => {
      listener = await CapacitorApp.addListener('backButton', () => {
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1)
        } else {
          CapacitorApp.exitApp()
        }
      })
    }
    setupListener()
    return () => {
      if (listener) listener.remove()
    }
  }, [navigate])

  return (
    <HelmetProvider>
      <Provider store={ storeRef.current }>
        <Routes>
          <Route
            path='/:locale/*'
            element={
              <>
                <div className='flex flex-col min-h-screen text-text-black bg-emerald-100'>
                  <Header />
                  <Aside />
                  <Main />
                  <Footer />
                </div>
              </>
            }
          />
        </Routes>
      </Provider>
    </HelmetProvider>
  )
}

export default Layout
